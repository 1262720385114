<template>
    <div>
        <div v-if="successMessage" class="alert alert-success" style="color: rgb(5, 20, 48)">
            {{ successMessage }}
        </div>
        <b-card>
            <b-row>
                <div class="col-12 mt-16">
                    <b-form @submit.prevent="addUser" v-if="show">
                        <div style="
                background-color: rgb(97, 116, 152);
                height: 32px;
                border-radius: 4px;
              ">
                            <h4 style="
                  color: rgb(223, 227, 238);
                  margin-left: 5px;
                  font-weight: bold;
                ">
                                Vehicle  Information
                            </h4>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Company Name:" label-for="company_name">
                                    <b-form-input id="company_name" placeholder="Enter company name"
                                        v-model="company_name" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                             
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Brand Name:" label-for="brand_id">
                                    <b-form-select id="brand_id" placeholder="Select brand id" v-model="brand_id" :options="brands" required>
                                    </b-form-select>
                                </b-form-group>
                            </div>
                            
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Brand Model:" label-for="brand_model_id">
                                    <b-form-select id="brand_model_id" placeholder="Select brand model" v-model="brand_model_id" :options="brandModels" required>
                                    </b-form-select>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Vehicle Color:" label-for="color">
                                    <b-form-input id="color" placeholder="Enter vehicle color"
                                        v-model="color"required>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="License Plate: "
                                    label-for="license_plate">
                                    <b-form-input id="license_plate"
                                        placeholder="Enter license plate" v-model="license_plate" required
                                        ></b-form-input>
                                        <div v-if="errorMessage" class="alert alert-danger" style="color: rgb(220, 53, 69)">
                                    {{ errorMessage.license_plate }}
                                </div>
                                </b-form-group>
                            </div>
               
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Engine Number: "
                                    label-for="engine_number">
                                    <b-form-input id="engine_number"
                                        placeholder="Enter engine number" v-model="engine_number" required
                                        ></b-form-input>
                                        <div v-if="errorMessage" class="alert alert-danger" style="color: rgb(220, 53, 69)">
                                    {{ errorMessage.engine_number }}
                                </div>
                                </b-form-group>
                            </div>
                           
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Registration  Number: "
                                    label-for="registration_number">
                                    <b-form-input id="registration_number"
                                        placeholder="Enter registration number" v-model="registration_number"
                                        required></b-form-input>
                                        <div v-if="errorMessage" class="alert alert-danger" style="color: rgb(220, 53, 69)">
                                    {{ errorMessage.registration_number }}
                                </div>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Vehicle Year: "
                                    label-for="year">
                                    <b-form-input id="year" 
                                        placeholder="Enter vehicle year" v-model="year"
                                        required></b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Vehicle Identification Number: " label-for="vin">
                                <b-form-input id="vin" placeholder="Enter vehicle identification number:" v-model="vin" required></b-form-input>
                                <div v-if="errorMessage" class="alert alert-danger" style="color: rgb(220, 53, 69)">
                                    {{ errorMessage.vin }}
                                </div>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Unique Identification Code:" label-for="uic">
                                <b-form-input id="uic" placeholder="Enter unique identification code" v-model="uic" required></b-form-input>
                                <div v-if="errorMessage" class="alert alert-danger" style="color: rgb(220, 53, 69)">
                                    {{ errorMessage.uic }}
                                </div>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                            <b-form-group id="input-group-2" label="Vehicle Images:" label-for="image">
                                <div style="margin-left: 3px; margin-bottom: 15px">
                                    <input type="file" accept="image/*" id="image" multiple @change="onProfilePictureChange" />
                                </div>
                            </b-form-group>
                        </div>

                        <div class="col-md-4 col-12">
                            <b-form-group id="input-group-2" label="Documents:" label-for="documents">
                                <div style="margin-left: 3px; margin-bottom: 15px">
                                    <input type="file" accept="documents/*" id="documents" multiple @change="onDocumentsChange" />
                                </div>
                            </b-form-group>
                        </div>
                        </div>

                        <!-- Bank Information End -->
                        <b-button type="submit" variant="primary" class="mb-8 mr-8" :disabled="isLoading">
                            <span v-if="!isLoading">Submit</span>
                            <b-spinner v-else class="mb-8 mr-8" variant="primary" small></b-spinner>
                        </b-button>
                    </b-form>
                </div>
                
                <div v-if="codeActive" class="col-12 mt-24 hljs-container" :class="{ active: codeActiveClass }">
                    <pre v-highlightjs>
            <code class="hljs html">
              {{ codeText }}
            </code>
          </pre>
                </div>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormSelect,
    BFormInput,
    BSpinner,
} from "bootstrap-vue";
import axios from "../../../axios";
import { BToast } from "bootstrap-vue";
import code from "@/view/components/data-entry/form/code";

export default {
    data() {
        return {
            show: true,
            codeText: code.introduction,
            codeActive: false,
            codeActiveClass: false,
            selectedOption: "",
            inputField1: "",
            inputField2: "",
            inputField3: "",
            showModal: false,
            isLoading: false,
            brand_id: "", 
            brands: [],
            brand_model_id: '',
            brandModels:[],
            company_name: "",
            color: "",
            license_plate: "",
            engine_number:"",
            types: "Approved",
            registration_number: "",
            vin: "",
            uic: "",
            year: "",
            status: "safe",
            image: [], 
            user_id: 1,
            successMessage: "",
            errorMessage: "",
            documents: [],
            vehicleId: "",

        };
    },
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BForm,
        BFormGroup,
        BFormCheckboxGroup,
        BFormCheckbox,
        BFormSelect,
        BFormInput,
        BToast, 
        BSpinner,
    },
    mounted() {
                axios.get('brand')
                    .then(response => {
                        this.brands = response.data.data.map(brand => ({
                            value: brand.id,  
                            text: brand.name  
                        }));
                        console.log(this.brands);
                    })
                    .catch(error => {
                        console.log(error);
                    });
                    axios.get('brandModel')
                        .then(response => {
    
                            this.brandModels = response.data.data.map(model => ({
                                value: model.id,  
                                text: model.title 
                            }));
                            console.log(this.brandModels);
                        })
                        .catch(error => {
                            console.log(error);
                        });
            },

    methods: {
        showMsgBoxOne() {
            debugger;
        },
        onSubmit(event) {
            event.preventDefault();
            alert(JSON.stringify(this.form));
            // debugger
        },
      
      

        addUser() {
    this.isLoading = true;
    this.errorMessage = ""; // Reset the error message
    const formData = new FormData();
    formData.append("brand_model_id", this.brand_model_id);
    formData.append("brand_id", this.brand_id)
    formData.append("company_name", this.company_name);
    formData.append("color", this.color);
    formData.append("license_plate", this.license_plate);
    formData.append("types", this.types);
    formData.append("engine_number", this.engine_number);
    formData.append("registration_number", this.registration_number);
    formData.append("vin", this.vin);
    formData.append("uic", this.uic);
    formData.append("year", this.year);
    // formData.append("documents", this.documents);
    formData.append("status", this.status);
    formData.append("user_id", this.user_id);  
      // Append each image to FormData
      if (this.image && this.image.length > 0) {
                this.image.forEach((file, index) => {
                    formData.append(`image[${index}]`, file);
                });
            }
            // Document code here  
            // if (this.documents && this.documents.length > 0) {
            //     this.documents.forEach((file, index) => {
            //         formData.append(`documents[${index}]`, file);
            //     });
            if (this.documents && this.documents.length > 0) {
            this.documents.forEach((file, index) => {
                formData.append(`documents[${index}]`, file);
            });
            }

        axios.post("vehicleStore", formData)
        .then((response) => {
            if (response.data.success) {
                this.$bvToast.toast("Vehicle Add successfully!", {
                    title: "Success",
                    variant: "success",
                    solid: true,
                    appendToast: true,
                    toaster: "b-toaster-top-right",
                    autoHideDelay: 5000,
                    variant: "primary",
                });
            } else {
          this.errorMessage = response.data.message || "An error occurred.";
        // this.errorMessage = response.data.message || "{}";
        }
            this.isLoading = false;
        })
        .catch((error) => {
            this.errorMessage = error.response.data.message; // Set a generic error message
            console.log(error.response.data);
            this.isLoading = false;
        });
},              
                onProfilePictureChange(event) {
                const files = event.target.files;

                if (files && files.length > 0) {
                    if (files.length > 3) {
                    // Show an error message if more than 3 images are selected
                    this.$bvToast.toast("You can only upload up to 3 images.", {
                        title: "Error",
                        variant: "danger",
                        solid: true,
                        appendToast: true,
                        toaster: "b-toaster-top-right",
                        autoHideDelay: 5000,
                        variant: "primary",
                    });

                    // Optionally, trim the selection to the first 3 images
                    this.image = Array.from(files).slice(0, 3);
                    } else {
                    // Store the selected images if 3 or fewer are selected
                    this.image = Array.from(files);
                    }
                }
                },
                onProfilePictureChangeOne(event) {
            const file = event.target.files[0];
            if (file) {
                // Set the selected file to the data property
                this.documents = file;
            }
        },

        onDocumentsChange(event) {
            const files = event.target.files;
            
            if (files && files.length > 0) {
                if (files.length > 3) {
                    this.$bvToast.toast("You can only upload up to 3 documents.", {
                        title: "Error",
                        variant: "danger",
                        solid: true,
                        appendToast: true,
                        toaster: "b-toaster-top-right",
                        autoHideDelay: 5000,
                        variant: "primary",
                    });

                    this.documents = Array.from(files).slice(0, 3);
                } else {
                    this.documents = Array.from(files);
                }
            }
        },
        codeClick() {
            this.codeActive = !this.codeActive;
        },

        saveOwnCar() {
            this.showModal = false;
        },
    },
};
</script>